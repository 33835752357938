
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import GridLayout from 'react-grid-layout';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import HTML from './html';
import Image from './image';

class Grid extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    onLayoutChange = (layout) => {
        console.log(layout);
        //return;
        let arr = [];
        for (let i = 0; i < layout.length; i++) {
            arr.push({ idx: layout[i].i, position: layout[i].y * 12 + layout[i].x });
        }

        console.log(arr)

        arr.sort(function (a, b) { return (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0); });

        let columns = [];

        for (let i = 0; i < this.props.value.length; i++) {
            columns.push(this.props.value[parseInt(arr[i].idx)]);
        }

        this.props.onChange(columns);
        this.forceUpdate();
    }


    addColumn = () => {
        let columns = this.props.value ? this.props.value : [];

        columns.push({
            width: {
                xs: 12,
                sm: 12,
                lg: 12
            },
            type: 'text'
        });

        this.props.onChange(columns);
        this.forceUpdate();
    }



    removeItem = (idx) => {
        let columns = this.props.value;
        columns.splice(idx, 1);
        this.props.onChange(columns);
        this.forceUpdate();
    }




    render() {

        let items = [];
        let layouts = [];


        let x = 0;
        let y = 0;

        if (this.props.value) {

            for (let i = 0; i < this.props.value.length; i++) {
                let width = parseInt(this.props.value[i] && this.props.value[i].width && this.props.value[i].width.lg ? this.props.value[i].width.lg : 6);
                let layout = { i: i.toString(), x: x, y: y, w: width, h: 1 };

                let item = {
                    content: (
                        <div key={i.toString()} className="grid-column-item" data-grid={layout}>
                            <div className="head">
                                <div className="drag-handle"><i className="fa fa-arrows"></i></div>
                            </div>
                            <Row>
                                {this.props.value[i].type != 'section' ?
                                    <>
                                        <Col lg="3">
                                            <FormGroup>
                                                <Label size="sm">Desktop</Label>
                                                <Input size="sm" type="select" onChange={(e) => {
                                                    let columns = this.props.value;
                                                    columns[i].width.lg = parseInt(e.target.value);
                                                    this.props.onChange(columns);
                                                    this.forceUpdate()

                                                }} value={this.props.value[i] && this.props.value[i].width && this.props.value[i].width.lg ? this.props.value[i].width.lg : 6}>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>

                                                </Input>
                                            </FormGroup>

                                        </Col>
                                        <Col lg="3">
                                            <FormGroup>
                                                <Label size="sm">Tablet</Label>
                                                <Input size="sm" type="select" onChange={(e) => {
                                                    let columns = this.props.value;
                                                    columns[i].width.sm = e.target.value;
                                                    this.props.onChange(columns);

                                                }} value={this.props.value[i] && this.props.value[i].width && this.props.value[i].width.sm ? this.props.value[i].width.sm : 12}>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>

                                                </Input>
                                            </FormGroup>

                                        </Col>
                                        <Col lg="3">
                                            <FormGroup>
                                                <Label size="sm">Mobile</Label>
                                                <Input size="sm" type="select" onChange={(e) => {
                                                    let columns = this.props.value;
                                                    columns[i].width.xs = e.target.value;
                                                    this.props.onChange(columns);

                                                }} value={this.props.value[i] && this.props.value[i].width && this.props.value[i].width.xs ? this.props.value[i].width.xs : 12}>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>

                                                </Input>
                                            </FormGroup>

                                        </Col>
                                    </>
                                    :
                                    null
                                }
                                <Col lg="3">
                                    <FormGroup>
                                        <Label size="sm">Type</Label>
                                        <Input size="sm" type="select" onChange={(e) => {
                                            let columns = this.props.value;
                                            columns[i].type = e.target.value;
                                            if (e.target.value == 'section' || e.target.value == 'components') {
                                                columns[i].width = {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                }
                                            }
                                            this.props.onChange(columns);
                                            this.forceUpdate();

                                        }} value={this.props.value[i].type}>
                                            <option value="text">Text</option>
                                            <option value="media">Image</option>
                                            {/* <option value="section">Section</option> */}
                                        </Input>
                                    </FormGroup>

                                </Col>

                            </Row>
                            <div className="area">
                                {this.props.value[i].type == 'text' ?

                                    <HTML height={328}
                                        onChange={(val) => {
                                            // let columns = this.props.value;
                                            // // columns[i].value = value;

                                            if (this.props.multilang) {
                                                // let value = this.props.value;
                                                let columns = this.props.value;
                                                if (!columns[i].value) {
                                                    columns[i].value = {};
                                                }
                                                columns[i].value[this.props.lang] = val;

                                                this.props.onChange(columns);
                                            } else {

                                                let columns = this.props.value;
                                                columns[i].value = value;

                                                this.props.onChange(columns);
                                            }
                                            this.forceUpdate();

                                          

                                        }}
                                        value={this.props.multilang ? (this.props.value && this.props.value[i] && this.props.value[i].value && this.props.value[i].value[this.props.lang]) ? this.props.value[i].value[this.props.lang] : '' : this.props.value[i].value}
                                    ></HTML>

                                    :
                                    this.props.value[i].type == 'media' ?
                                        <Image height={300}
                                            onChange={(value) => {
                                                let columns = this.props.value;
                                                columns[i].value = value;
                                                this.props.onChange(columns);
                                                this.forceUpdate();

                                            }} value={this.props.value[i].value} />
                                        :
                                        this.props.value[i].type == 'section' ?
                                            <>
                                                <HTML height={328}
                                                    onChange={(value) => {
                                                        let columns = this.props.value;
                                                        columns[i].value = value;
                                                        this.props.onChange(columns);
                                                        this.forceUpdate();

                                                    }}
                                                    value={this.props.value[i].value}
                                                ></HTML>

                                                <Image height={300}
                                                    onChange={(value) => {
                                                        let columns = this.props.value;
                                                        columns[i].bgImage = value;
                                                        this.props.onChange(columns);
                                                        this.forceUpdate();

                                                    }} value={this.props.value[i].bgImage} />
                                            </>
                                            :

                                            null
                                }
                            </div>
                            <div className="footer">
                                <Button size="sm" color="danger" onClick={() => this.removeItem(i)}><i className="fa fa-trash" ></i></Button>

                            </div>

                        </div>
                    )
                };
                layouts.push(layout);
                items.push(item);
                x += width;
                if (x >= 12) {
                    y++;
                    x = 0;
                }
            }

        }

        return (
            <div className={'grid-view-area'} ref={(node) => this.wrap = node}>
                <GridLayout
                    className="content-grid-layout"
                    onLayoutChange={this.onLayoutChange}
                    width={this.wrap ? this.wrap.offsetWidth - 100 : 1000}
                    draggableHandle={".drag-handle"}
                    rowHeight={550}
                    //compactType={'horizontal'}
                    isResizable={false}
                    horizontalCompact={true}
                    layout={layouts}
                    useCSSTransforms={false}
                    cols={12}
                >
                    {
                        items.map((item, idx) => {
                            return (
                                item.content
                            );
                        })
                    }

                </GridLayout>

                <div className="grid-footer">
                    <Button type="button" onClick={this.addColumn}>Add element</Button>
                </div>


            </div>
        );
    }
}

export default Grid;