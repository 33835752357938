import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/formBuilder'
import { required } from '../../components/forms/validation'
import Search from '../../components/search'

import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import rightIcon from '../../assets/svg/arrow-right.svg'

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            category: [],
            types: [
                {
                    name: 'Reference',
                    value: 1
                },
                {
                    name: 'O nama',
                    value: 2
                },
                {
                    name: 'Djelatnosti',
                    value: 3
                },
                {
                    name: 'Aktuelnosti',
                    value: 4
                },
                {
                    name: 'Dokumenta',
                    value: 5
                },

            ]
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props[0].match.params.id == 'new') {
                    fetch(API_ENDPOINT + '/data/menu/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push('/menu/list')
                        })
                } else {
                    fetch(API_ENDPOINT + '/data/menu/' + this.props[0].match.params.id, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push('/menu/list')
                        })
                }
            }
        )
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/menu/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        return (
            <div className='page'>
                <Container className='dashboard'>

                <Search updateParams={this.updateParams} lang={this.props.lang} setLang={(lang) => this.props.setLang(lang)} hideSearch={true}/>

                    <FormBuilder

                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.initialValues}

                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    //HEADER
                                                    {//naslov forme
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'div',
                                                                className: 'form-header',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Meni'.translate(this.props.lang)
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    //END HEADER
                                                    //FORMA za unos vijesi
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'name',
                                                                label: 'Naziv'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                                validate: [required('Naziv je obavezan'.translate(this.props.lang))]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'link',
                                                                label: 'Link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                                validate: [required('Link je obavezan'.translate(this.props.lang))]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'select',
                                                                name: 'type',
                                                                multiple: false,
                                                    
                                                                values: this.state.types.map(item => {

                                                                    return {
                                                                        name: item.name.translate(this.props.lang),
                                                                        value: item.value
                                                                    }

                                                                }),
                                                                label: 'Tip'.translate(this.props.lang),
                                                                validate: [required('Tip je obavezan'.translate(this.props.lang))]
                                                                
                                                            },
                                                        ]
                                                    },
                                                    // {
                                                    //     type: 'col',
                                                    //     width: {
                                                    //         lg: 12, sm: 12, xs: 12
                                                    //     },
                                                    //     children: [
                                                    //         {
                                                    //             type: 'text',
                                                    //             name: 'uniqueID',
                                                    //             label: 'Jedinstveni id'
                                                    //         },

                                                    //     ]
                                                    // },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'submit-button',
                                                                className: 'main-form-button button-1',
                                                                text: this.props[0].match.params.id != 'new' ? 'Izmjeni kategoriju'.translate(this.props.lang) : 'Dodaj kategoriju'.translate(this.props.lang)
                                                            }
                                                        ]
                                                    }

                                                ]
                                            }]
                                        }
                                        ]
                                    },
                                    //END FORM za unos vijesti
                                    // {
                                    //     type: 'col',
                                    //     width: {
                                    //         lg: 3
                                    //     },
                                    //     children: [
                                    //         {//vrijeme objave
                                    //             type: 'div',
                                    //             className: 'component customer-age',
                                    //             children: [
                                    //                 {
                                    //                     type: 'h5',
                                    //                     text: 'Ikonica',
                                    //                     className: 'component-header'
                                    //                 },
                                    //                 {
                                    //                     type: 'image',
                                    //                     height: 220,
                                    //                     name: 'icon',
                                    //                 },

                                    //             ]
                                    //         },

                                    //     ]
                                    // }
                                ]
                            }
                        ]}
                    ></FormBuilder>
                </Container>
            </div >
        )
    }
}

export default Page(Form)
