import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <ul className="navigation">
                <li>
                    <a>
                        <span className="link-text">Početna</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span className="link-text">Interni događaji</span>
                    </a>
                </li>  
                <li>
                    <a>
                        <span className="link-text">Organizaciona struktura</span>
                    </a>
                </li> 
                <li>
                    <a>
                        <span className="link-text">Procedure i uputstva</span>
                    </a>
                </li> 
                <li>
                    <a>
                        <span className="link-text">Radnici</span>
                    </a>
                </li> 
                <li>
                    <a>
                        <span className="link-text">Ankete</span>
                    </a>
                </li> 
                <li>
                    <a>
                        <span className="link-text">Planer</span>
                    </a>
                </li> 
                <li>
                    <a>
                        <span className="link-text">Edukativni materijal</span>
                    </a>
                </li>      
            </ul>
        )
    }
}

export default Navigation;