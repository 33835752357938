import HomePage from './views/homePage'

import Login from './views/loginPage'

import { API_ENDPOINT } from './constants'

import news from './views/news'
import addNews from './views/addNews';
import addCategory from './views/addCategory';
import category from './views/category';


import MenuList from './views/menu/list';
import MenuForm from './views/menu/form';

import NewsCategoriesList from './views/newsCategories/list';
import NewsCategoriesForm from './views/newsCategories/form';

import SubcategoriesList from './views/subcategories/list';
import SubcategoriesForm from './views/subcategories/form';

import NewsArticlesList from './views/newsArticles/list';
import NewsArticlesForm from './views/newsArticles/form';

import ReferencesList from './views/references/list';
import ReferencesForm from './views/references/form';

import BlogList from './views/blogs/list';
import BlogForm from './views/blogs/form';

import DocumentsList from './views/documents/list';
import DocumentsForm from './views/documents/form';


import AdminsList from './views/admins/list';
import AdminsForm from './views/admins/form';


import UsersList from './views/users/list';
import UsersForm from './views/users/form';



import SettingsForm from './views/settings/form';
import GalleryForm from './views/gallery/form';
import FortisGroupForm from './views/fortisGroup/form';

import ContactsList from './views/contact/list';
import ContactSettingsForm from './views/contact/settings';



function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    }
  })
}

export const routes = [
  {
    path: '/',
    component: HomePage,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
      
    ],
    loadDataWithQuery: [
      
    ],
  },

  {
    path: '/menu/list',
    component: MenuList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/menu', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/menu/:id',
    component: MenuForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },


  {
    path: '/news/categories/list',
    component: NewsCategoriesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/categories', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
            lang: lang
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/categories/:id',
    component: NewsCategoriesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  {
    path: '/news/subcategories/list',
    component: SubcategoriesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/subcategories', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
            lang: lang
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/subcategories/:id',
    component: SubcategoriesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/subcategories/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  

  {
    path: '/news/articles/list',
    component: NewsArticlesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/articles', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/articles/:id',
    component: NewsArticlesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/subcategories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              subcategories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/articles/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },



  {
    path: '/news/blog/list',
    component: BlogList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
     

     
    ],
    loadDataWithQuery: [
      (fetchFunction, lang, match, category, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/blog', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
            category: category
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/blog/:id',
    component: BlogForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/subcategories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              subcategories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/blog/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/references/list',
    component: ReferencesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/references', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/references/:id',
    component: ReferencesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      // (fetchFunction, lang, match, query) => {
      //   return fetchFunction(API_ENDPOINT + '/data/news/categories/all', {
      //     method: 'GET',
      //     headers: {
      //       'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
      //       'content-type': 'application/json'
      //     },
      //   }).then(parseJSON).then(({ result, status }) => {
      //     if (status >= 200 && status < 300)
      //       return {
      //         categories: result,
      //       }
      //   })
      // },
      // (fetchFunction, lang, match, query) => {
      //   return fetchFunction(API_ENDPOINT + '/data/news/subcategories/all', {
      //     method: 'GET',
      //     headers: {
      //       'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
      //       'content-type': 'application/json'
      //     },
      //   }).then(parseJSON).then(({ result, status }) => {
      //     if (status >= 200 && status < 300)
      //       return {
      //         subcategories: result,
      //       }
      //   })
      // },
      // (fetchFunction, lang, match, query) => {
      //   return fetchFunction(API_ENDPOINT + '/data/menu/all', {
      //     method: 'GET',
      //     headers: {
      //       'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
      //       'content-type': 'application/json'
      //     },
      //   }).then(parseJSON).then(({ result, status }) => {
      //     if (status >= 200 && status < 300)
      //       return {
      //         menu: result,
      //       }
      //   })
      // },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/references/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  
  {
    path: '/contacts/list',
    component: ContactsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/contacts', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/documents/list',
    component: DocumentsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/documents', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/documents/:id',
    component: DocumentsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/documents/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

 

  {
    path: '/admins/list',
    component: AdminsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/admins', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/admins/:id',
    component: AdminsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/admins/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  

  {
    path: '/users/list',
    component: UsersList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/users', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/users/:id',
    component: UsersForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/users/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  
  
  {
    path: '/fortis-group',
    component: FortisGroupForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/fortisGroup', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
          
            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [
   
    ],
  },
  {
    path: '/gallery',
    component: GalleryForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/gallery', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
          
            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [
   
    ],
  },
  
  
  {
    path: '/contact/settings',
    component: ContactSettingsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/settings/contact', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
          
            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [
   
    ],
  },
  

  {
    path: '/settings',
    component: SettingsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/home', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
          
            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [
   
    ],
  },
  
  
  


  {
    path: '/login',
    component: Login,
    redirectUser: '/',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Bijeljina put '.translate(lang),
      }
    },
    loadData: [],
  },

  

]

export function generateRoutes(r) {
  let newRoutes = []
  for (let i = 0; i < r.length; i++) {
    newRoutes.push(r[i])

    /*newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })*/
  }
  return newRoutes
}
