import React, { Component } from 'react'

import Isvg from 'react-inlinesvg'
import { Pie } from 'react-chartjs-2';
import Page from '../containers/page'

import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap'

import { Link, Redirect } from 'react-router-dom';


import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'

import Navigation from '../components/navigation'

// import profile from '../assets/svg/profile-image.svg';
import profile from '../assets/no-image.jpg';
import map from '../assets/svg/map.svg'
import chart from '../assets/svg/chart.svg';
import deskIcon from '../assets/svg/desk-icon.svg';
import warnningIcon from '../assets/svg/warnning-icon.svg';
import chartIcon from '../assets/svg/chart-icon.svg';
import mailIcon from '../assets/svg/mail-icon.svg';
import arrowRight from '../assets/svg/arrow-right.svg'
import searchIcon from '../assets/svg/search.svg'
import weatherIcon from '../assets/svg/weather-icon.svg'
import arrowUp from '../assets/svg/arrow-up.svg'
import arrowDown from '../assets/svg/arrow-down.svg'

import logo from '../assets/logo.svg';
import zajednica from '../assets/zajednica.svg';

var striptags = require('striptags')

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotedProjects: [],
      ...props.initialData,
      form: false,

      useState: false,
      bannerForm: false,
      params: {},
      areas: [],
      news: [],
      weatherId: 0,
      cities: [
        '3204541',
        '3204186',
        '3201984',
        '3188893',
        '3191281',
        '3188582',
        '3194828',
        '3204222',
        '3192409',
        '3199779',
        '3203521',
        '3186423',
        '3186573',
        '3203099',
        '3200837',
      ]
    }
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    this.get()

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
    this.getWeather();
  }

  toggle = () => {
    this.setState({
      form: !this.state.form,
    })
  }


  getWeather = () => {
    fetch('https://api.openweathermap.org/data/2.5/weather?id=' + this.state.cities[this.state.weatherId] + '&appid=ead872841a3f922859ce83fabb5b5e39')
      .then(res => res.json()).then((result) => {
        // console.log(result);
        let icon_path = `https://openweathermap.org/img/w/` + result.weather[0].icon + `.png`;
        this.setState({
          city: result.name,
          temp: Math.round(result.main.temp - 273),
          weatherIcon: icon_path
        })
      })
      .catch(function () {
      });
  }
  decWeatherId = () => {
    if (this.state.weatherId > 0) {
      this.setState({ weatherId: this.state.weatherId - 1 }, () => this.getWeather())
    } else {
      this.setState({ weatherId: this.state.cities.length - 1 }, () => this.getWeather())
    }

  }
  incWeatherId = () => {
    if (this.state.weatherId < this.state.cities.length - 1) {
      this.setState({ weatherId: this.state.weatherId + 1 }, () => this.getWeather())
    } else {
      this.setState({ weatherId: 0 }, () => this.getWeather())
    }

  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          {},
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          {},
          fields,
          restart
        )
      )
    }
  }

  render() {

    const data = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let params = getSearchParams(this.props[0].location.search, {
      entries: 10,
      page: 0,
    })

    console.log(params)

    return (
      <div className='page'>
        {/* {
          typeof localStorage !== 'undefined' && !localStorage.authToken ? <Redirect to='/login' /> : null
        } */}
        <Container className='dashboard padding-top0'>
          <Row>
            <Col lg='9' sm='12' xs='12'>
              <div className="dashboard-content">

                <div class='logo-home'>
                  <img src={logo} className="logo-home1" />
                </div>

              </div>
            </Col>
            <Col lg='3' sm='12' xs='12'>

              <div className="help-bar">
                <div className="help-bar-content">

                  <div className="help-bar-header">
                    <div className='profile'>
                      {
                        this.props.uData ?
                          <>
                            <span className='name'>
                              {this.props.uData && this.props.uData.username}
                            </span>
                            <img src={profile} className='profile-image' />
                          </>
                          :
                          <a href="/login">
                            <span className='name'>
                              Prijavi se
                            </span>
                          </a>
                      }

                    </div>

                    {/* <div className="search-container">
                      <Isvg src={searchIcon} className="search-icon" />
                      <input type="text" className="search" placeholder="Pretraga..." />
                    </div> */}
                    <div className="language-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle className="menu-link">
                          <span className="link-text">{this.props.lang == 'ba' ? 'BA' : this.props.lang == 'en' ? 'ENG' : 'DE'}</span>
                          <Isvg src={arrowDown} />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.props.setLang('ba')}>BA</DropdownItem>
                          <DropdownItem onClick={() => this.props.setLang('en')}>ENG</DropdownItem>
                          <DropdownItem onClick={() => this.props.setLang('de')}>DE</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <div className="weather-wrap">
                      <div className="weather">
                        <h6>Vremenska prognoza</h6>
                        <div className="weather-content">
                          {
                            this.state.weatherIcon ?
                              <img src={this.state.weatherIcon} alt="" />
                              :
                              null

                          }
                          <div className="weather-info">
                            <span className="temperature">{this.state.temp && this.state.temp} &deg;C  </span> <br />
                            <span className="city">{this.state.city && this.state.city}</span>

                          </div>
                          <div className="next-btn-wrap">
                            <button className="next-btn" onClick={() => this.decWeatherId()}>&lt;</button>
                            <button className="next-btn" onClick={() => this.incWeatherId()}>&gt;</button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page(HomePage)
