import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import error from '../assets/images/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    <img src={error} />
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                    <div className="delete-warning-wrap">
                        {
                            this.props.isOpen && this.props.isOpen.checkedObjectForDelete &&
                                (
                                    (this.props.isOpen.checkedObjectForDelete.categoriesForDelete && this.props.isOpen.checkedObjectForDelete.categoriesForDelete.length) ||
                                    (this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete && this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete.length) ||
                                    (this.props.isOpen.checkedObjectForDelete.blogsForDelete && this.props.isOpen.checkedObjectForDelete.blogsForDelete.length)
                                ) ?
                                <div className="delete-warning">
                                    <div className="title"><h2>{"Upozorenje".translate(this.props.lang)}</h2></div>
                                    <div className="description"><p>{"Odabrani element je povezan sa sledećim elementima i njegovim brisanjem biće obrisani i svi elementi koji su povezani sa njim!".translate(this.props.lang)}</p></div>
                                    <hr/>
                                    {
                                        this.props.isOpen.checkedObjectForDelete.categoriesForDelete && this.props.isOpen.checkedObjectForDelete.categoriesForDelete.length ?
                                            <div className="linked-element-wrap">
                                                <h5>{"Kategorije:".translate(this.props.lang)}</h5>
                                                {
                                                    this.props.isOpen.checkedObjectForDelete.categoriesForDelete && this.props.isOpen.checkedObjectForDelete.categoriesForDelete.map((item, idx) => {
                                                        return (
                                                            <div className="linked-element">
                                                                <h6>&#9733;{Object.translate(item, 'name', this.props.lang)}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                 <hr/>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete && this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete.length ?
                                            <div className="linked-element-wrap">
                                                <h5>{"Potkategorije:".translate(this.props.lang)}</h5>
                                                {
                                                    this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete && this.props.isOpen.checkedObjectForDelete.subcategoriesForDelete.map((item, idx) => {
                                                        return (
                                                            <div className="linked-element">
                                                                <h6>&#9733;{Object.translate(item, 'name', this.props.lang)}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <hr/>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.props.isOpen.checkedObjectForDelete.blogsForDelete && this.props.isOpen.checkedObjectForDelete.blogsForDelete.length ?
                                            <div className="linked-element-wrap">
                                                <h5>{"Blogovi:".translate(this.props.lang)}</h5>
                                                {
                                                    this.props.isOpen.checkedObjectForDelete.blogsForDelete && this.props.isOpen.checkedObjectForDelete.blogsForDelete.map((item, idx) => {
                                                        return (
                                                            <div className="linked-element">
                                                                <h6>&#9733;{Object.translate(item, 'name', this.props.lang)}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                 <hr/>
                                            </div>
                                            :
                                            null
                                    }

                                </div>

                                :
                                null
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;