
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/picture.svg';
import { API_ENDPOINT } from '../../../constants';

class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            this.setState({
                _loading: true
            })

            let formData = new FormData();
            formData.append('file', input.files[0]);

            fetch(API_ENDPOINT + '/upload-image', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                },
                body: formData
            }).then((res) => res.text()).then((img) => {
                this.props.onChange(img);
                this.setState({
                    _loading: null
                })
            });
        }
    }

    render() {
        return (
            <FormGroup className="image-picker-wrap">
                <Label size="sm">{this.props.label}</Label>

                <div className="image-picker" style={this.props.height ? {height: this.props.height} : {}}>
                    <input type="file" onChange={this.selectFile} />
                    {this.props.value ?
                        <img src={API_ENDPOINT + this.props.value} />
                        :
                        null
                    }
                    {
                        this.state._loading ?
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            :
                            null
                    }

                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }
                    {!this.props.hideInfo ?
                        <div className="bottom-content">
                            <Isvg src={image} />
                            <p> <span>Upladujte fajl </span> ili prevucite sliku u box</p>
                        </div>
                        :
                        null
                    }


                </div>

            </FormGroup>


        );
    }
}

export default Image;