import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/formBuilder'
import { required } from '../../components/forms/validation'
import Search from '../../components/search'

import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import rightIcon from '../../assets/svg/arrow-right.svg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            categories: [],
            menu: []
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }

        if (prevProps.menu != this.props.menu) {
            this.props.changeCategory(null);
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {

        if (data.menu && typeof data.menu != 'string') {
            data.menu = data.menu[0]
        }
        if (data.category && typeof data.category != 'string') {
            data.category = data.category[0]
        }

        this.setState(
            {
                loading: true,
            },
            () => {

                fetch(API_ENDPOINT + '/data/gallery', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                    body: JSON.stringify(data),
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })


            }
        )
    }



    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        return (
            <div className='page'>
                <Container className='dashboard'>

                <Search updateParams={this.updateParams} lang={this.props.lang} setLang={(lang) => this.props.setLang(lang)} hideSearch={true}/>
                    <FormBuilder

                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.initialValues}


                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    //HEADER
                                                    {//naslov forme
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'div',
                                                                className: 'form-header',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Galerija'.translate(this.props.lang)
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },

                                                    //FORMA za unos vijesi
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'gallery',
                                                                name: 'images',
                                                                // label: 'Naslov'
                                                            },
                                                        ]
                                                    },
                                                    






                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'submit-button',
                                                                className: 'main-form-button button-1 main-form-button-2',
                                                                text: 'Spremi'.translate(this.props.lang)
                                                            }
                                                        ]
                                                    }

                                                ]
                                            }]
                                        }
                                        ]
                                    },
                                   
                                ]
                            }
                        ]}
                    ></FormBuilder>
                </Container>
            </div >
        )
    }
}

const selector = formValueSelector('form');

export default connect(state => {
    return { menu: selector(state, 'menu') }

}, {
    changeCategory: value => change("form", "category", value),

})(Page(Form));