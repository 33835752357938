import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/formBuilder'
import { required } from '../../components/forms/validation'
import Search from '../../components/search'

import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import rightIcon from '../../assets/svg/arrow-right.svg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'
import moment from 'moment';


class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            categories: [],
            subcategories: [],
            menu: []
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }


    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }

        if (prevProps.menu && prevProps.menu != this.props.menu) {
            this.props.changeCategory(null);
        }
        if (prevProps.category && prevProps.category != this.props.category) {
            this.props.changeSubcategory(null);
        }
        if (prevProps.addSection && prevProps.addSection != this.props.addSection) {
            this.props.changeAddSection(null);
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {
        console.log(data)
        let today = new Date();
        let ts = Math.floor(today.getTime() / 1000);
        let date = moment.unix(ts).format("YYYY-MM-DD");
        let time = moment.unix(ts).format("HH:mm");

        data.date = date;
        data.time = time;

        if (data.menu && typeof data.menu != 'string') {
            data.menu = data.menu[0]
        }
        if (data.category && typeof data.category != 'string') {
            data.category = data.category[0]
        }
        data.lang = this.props.lang;

        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props[0].match.params.id == 'new') {
                    fetch(API_ENDPOINT + '/data/news/blog/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push({
                                pathname: '/news/blog/list',
                                state: { tab: data.menu }
                              })
                        })
                } else {
                    fetch(API_ENDPOINT + '/data/news/blog/' + this.props[0].match.params.id, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push({
                                pathname: '/news/blog/list',
                                state: { tab: data.menu }
                              })
                        })
                }
            }
        )
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/news/blog/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        // console.log(this.props.uData.menu)

        return (
            <div className='page'>
                <Container className='dashboard'>
                <Search updateParams={this.updateParams} lang={this.props.lang} setLang={(lang) => this.props.setLang(lang)} hideSearch={true}/>

                    {/* <Row>
                        <Col lg='9'>
                            <Search />
                        </Col>
                        <Col lg='3'>
                            <div className='component notifications'>
                                <div className="component-wrap">
                                    <Isvg src={notification} />
                                    <div className='profile'>
                                        <span className='name color-black'>
                                            {this.props.uData.username}
                                        </span>
                                        <Isvg src={profile} className='profile-image' />
                                        <Isvg src={moreIcon} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <FormBuilder

                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.initialValues}


                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    //HEADER
                                                    {//naslov forme
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'div',
                                                                className: 'form-header',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Dodavanje vijesti'.translate(this.props.lang)
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 4, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'select',
                                                                name: 'menu',
                                                                multiple: false,
                                                                values: this.state.menu.map(item => {
                                                                    return {
                                                                        name: item.name[this.props.lang],
                                                                        value: item._id
                                                                    }
                                                                }),
                                                                label: 'Meni'.translate(this.props.lang),
                                                                validate: [required('Meni je obavezan'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    this.props.menu ?
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 4, sm: 12, xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'select',
                                                                    name: 'category',
                                                                    multiple: false,
                                                                    values: this.state.categories.filter(item => {
                                                                        // this.props.menu == item.menu
                                                                        if (item.menu && item.menu.length) {
                                                                            for (let i = 0; i < item.menu.length; i++) {
                                                                                if (this.props.menu == item.menu[i]) {
                                                                                    return true;
                                                                                }
                                                                            }
                                                                            return false;
                                                                        }
                                                                    }).map(item => {

                                                                        return {
                                                                            name: item.name[this.props.lang],
                                                                            value: item._id
                                                                        }

                                                                    }),
                                                                    label: 'Kategorija'.translate(this.props.lang),
                                                                    validate: [required('Kategorija je obavezna'.translate(this.props.lang))]
                                                                },
                                                            ]
                                                        }
                                                        :
                                                        {
                                                            type: 'empty'
                                                        },
                                                    this.props.category && this.state.subcategories.filter(item => String(this.props.category) == String(item.category)).length ?
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 4, sm: 12, xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'select',
                                                                    name: 'subcategory',
                                                                    multiple: false,
                                                                    values: this.state.subcategories.filter(item => String(this.props.category) == String(item.category)).map(item => {

                                                                        return {
                                                                            name: item.name[this.props.lang],
                                                                            value: item._id
                                                                        }

                                                                    }),
                                                                    label: 'Potkategorija'.translate(this.props.lang),
                                                                },
                                                            ]
                                                        } :
                                                        {
                                                            type: 'empty'
                                                        },
                                                        


                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 9, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                className: 'pl-0',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Naslov'.translate(this.props.lang),
                                                                        validate: [required('Naslov je obavezan'.translate(this.props.lang))]
                                                                    },
                                                                ]
                                                            },
                                                            
                                                            {
                                                                type: 'col',
                                                                className: 'pl-0',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'shortDescription',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Uvodni tekst'.translate(this.props.lang),
                                                                        disabled: this.state.menu.filter((item) => item._id == this.props.menu && item.type != 5).length ? false : true
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                label: this.state.menu.filter((item) => item._id == this.props.menu && item.type != 5).length ? 'Naslovna fotografija'.translate(this.props.lang) : 'Fajl'.translate(this.props.lang),
                                                                type: 'image',
                                                                height: 210,
                                                                name: 'image'
                                                            }

                                                        ]
                                                    },
                                              


                                                    // {
                                                    //     type: 'col',
                                                    //     width: {
                                                    //         lg: 12, sm: 12, xs: 12
                                                    //     },
                                                    //     children: [
                                                    //         {
                                                    //             type: 'html',
                                                    //             name: 'content',
                                                    //             label: 'Detaljno'.translate(this.props.lang),
                                                    //             multilang: true,
                                                    //             lang: this.props.lang,
                                                    //             onChange: {}
                                                    //         },
                                                    //     ]
                                                    // },
                                                    this.state.menu.filter((item) => item._id == this.props.menu && item.type != 5).length ?
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'grid',
                                                                name: 'sections',
                                                                label: 'Sekcije'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                                onChange: {}
                                                            },
                                                        ]
                                                    } : 
                                                    {
                                                        type: 'empty'
                                                    },
                                                    this.state.menu.filter((item) => item._id == this.props.menu && item.type != 5).length ?
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'gallery',
                                                                name: 'gallery',
                                                                label: 'Galerija'
                                                            },
                                                        ]
                                                    }: 
                                                    {
                                                        type: 'empty'
                                                    },

                                                    this.state.menu.filter((item) => item._id == this.props.menu && item.type == 2).length ?
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'switch',
                                                                name: 'addSection',
                                                                label: 'Sekcija'.translate(this.props.lang),
                                                            }
                                                        ]
                                                    }:
                                                    {
                                                        type: 'empty'
                                                    },
                                                    this.props.addSection ? 
                                                    {
                                                        type: 'row',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'fluidSection.title',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Naslov'.translate(this.props.lang)
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'fluidSection.promoVideo',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Youtube ID'.translate(this.props.lang)
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'fluidSection.shortDescription',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Uvodni tekst'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'fluidSection.detail',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Tekst'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'fluidSection.number1',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Polje 1'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'fluidSection.number2',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Polje 2'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'fluidSection.number1text',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Polje 1 tekst'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'fluidSection.number2text',
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                        label: 'Polje 2 tekst'.translate(this.props.lang),
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 3, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        label: 'Naslovna fotografija'.translate(this.props.lang),
                                                                        type: 'image',
                                                                        height: 210,
                                                                        name: 'fluidSection.image'
                                                                    }
        
                                                                ]
                                                            },

                                                        ]
                                                    }
                                                     : 
                                                    {
                                                        type: 'empty'
                                                    },
                                                    


                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'submit-button',
                                                                className: 'main-form-button button-1',
                                                                text: this.props[0].match.params.id != 'new' ? 'Izmjeni'.translate(this.props.lang) : 'Dodaj'.translate(this.props.lang),
                                                            }
                                                        ]
                                                    }

                                                ]
                                            }]
                                        }
                                        ]
                                    },
                                    //END FORM za unos vijesti
                                    // {
                                    //     type: 'col',
                                    //     width: {
                                    //         lg: 3
                                    //     },
                                    //     children: [
                                    //         {
                                    //             type: 'div',
                                    //             className: 'component customer-age',
                                    //             children: [
                                    //                 {
                                    //                     type: 'h5',
                                    //                     text: 'Vrijeme objave'.translate(this.props.lang),
                                    //                     className: 'component-header'
                                    //                 },
                                    //                 {
                                    //                     type: 'date',
                                    //                     name: 'date',
                                    //                 },
                                    //                 {
                                    //                     type: 'time',
                                    //                     name: 'time',

                                    //                 }

                                    //             ]
                                    //         },
                                    //         {
                                    //             type: 'div',
                                    //             className: 'component gallery',
                                    //             children: [
                                    //                 {
                                    //                     type: 'h5',
                                    //                     className: 'component-header',
                                    //                     text: 'Fotografija'.translate(this.props.lang),
                                    //                 },
                                    //                 {
                                    //                     type: 'image',
                                    //                     height: 300,
                                    //                     name: 'image'
                                    //                 }
                                    //             ]
                                    //         },

                                    //     ]
                                    // }
                                ]
                            }
                        ]}
                    ></FormBuilder>
                </Container>
            </div >
        )
    }
}

const selector = formValueSelector('form');

export default connect(state => {
    return { menu: selector(state, 'menu'), category: selector(state, 'category'), addSection: selector(state, 'addSection') }

}, {
    changeCategory: value => change("form", "category", value),
    changeSubcategory: value => change("form", "subcategory", value),
    changeAddSection: value => change("form", "addSection", value),

})(Page(Form));