import React, { Component } from 'react'
import Isvg from 'react-inlinesvg'
import searchIcon from '../assets/svg/search.svg'


import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap'

import arrowDown from '../assets/svg/arrow-down.svg'


class Search extends Component {
  state = {
    query: '',
    results: [],
  }





  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: !this.props.hideSearch ? 'space-between' : 'flex-end' }}>
        {
          !this.props.hideSearch ?
            <>
              <input
                className='search-bar form-control'
                placeholder='Pretraži'
                ref={(input) => (this.search = input)}
                value={this.state.search}
                style={{ width: '80%' }}
                onChange={(e) => {
                  this.setState({
                    search: e.target.value
                  }, () => {
                    this.props.updateParams('filter', this.state.search)
                  })
                }}

              ></input>
              <Isvg src={searchIcon} className='search-icon' />
            </>
            :
            null
        }

        <div className="language-wrap2">
          <UncontrolledDropdown>
            <DropdownToggle className="menu-link">
              <span className="link-text">{this.props.lang == 'ba' ? 'BA' : this.props.lang == 'en' ? 'ENG' : 'DE'}</span>
              <Isvg src={arrowDown} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.props.setLang('ba')}>BA</DropdownItem>
              <DropdownItem onClick={() => this.props.setLang('en')}>ENG</DropdownItem>
              <DropdownItem onClick={() => this.props.setLang('de')}>DE</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>


      </div>
    )
  }
}

export default Search
