import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import Search from '../../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../../components/listBuilder'
import DeleteModal from '../../components/deleteModalWithWarning'
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

import garabageIcon from '../../assets/svg/garabage.svg'
import userIcon from '../../assets/svg/user-icon.svg'
import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import editIcon from '../../assets/svg/edit.svg'

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/news/subcategories/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }
    checkDelete = (item) => {
        

        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/subcategories/check/delete/' + item._id, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.setState({ checkedObjectForDelete: result}, () => {
                            item.checkedObjectForDelete = this.state.checkedObjectForDelete;
                            this.setState({ deleteModal: item })
                        })
                    })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }


        return (
            <div className='page'>
                <Container className='dashboard'>
                    <Row>
                        <Col lg='12'>
                            <Search updateParams={this.updateParams} lang={this.props.lang} setLang={(lang) => this.props.setLang(lang)}/>
                            <div className='panel'>
                                <div className='main-table-header'>
                                    <h5 className='component-header'>{"Lista".translate(this.props.lang)}</h5>
                                    <button className='add-news'>
                                        <Link to='/news/subcategories/new'>
                                            {"Dodaj potkategoriju".translate(this.props.lang)}
                                        </Link>
                                    </button>
                                </div>

                                <ListBuilder
                                    loading={this.state.loading && false}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        {
                                            type: 'text', class: 'labelbam', name: 'name', sortLabel: 'Po imenu', allowSort: true,
                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'categoryName', sortLabel: 'Po imenu', allowSort: true,
                                        },
                                        // {
                                        //     type: 'text', class: 'labelbam', name: 'menuName', sortLabel: 'Po imenu', allowSort: true,
                                        // }
                                    ]}
                                    rawItems={this.state.items}
                                    items={


                                        this.state.items.filter(item => {
                                            if (this.props.uData.menu){
                                                for (let i = 0; i < this.props.uData.menu.length; i++) {
                                                    if (String(this.props.uData.menu[i]) == String(item.menu)) {
                                                        return true;
                                                    }
                                                }
                                            }else{
                                                return true;
                                            }
                                                
                                        }).map((item, idx) => {

                                            return {
                                                ...item,
                                                name: item.name && item.name[this.props.lang]
                                            }

                                        })

                                    }
                                    actions={[
                                        {
                                            type: 'functionAction',
                                            render: (item) => <Link to={
                                                `/news/subcategories/${item._id}`
                                            }><Isvg src={editIcon} />
                                            </Link>,
                                        },
                                        {
                                            component: <Isvg src={garabageIcon} className className='edit-icon' />,
                                            // onClick: (item) => this.setState({ deleteModal: item })
                                            onClick: (item) => this.checkDelete(item)
                                        },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {"Obrisi".translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name && Object.translate(this.state.deleteModal, 'name', this.props.lang)}</strong>?
                  </DeleteModal>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(List)
