import React, { Component, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg'

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import socketIO from 'socket.io-client'
import moment from 'moment'
import signal from '../assets/svg/signal.svg'
import noSignal from '../assets/svg/no-signal.svg'

import home from '../assets/svg/home.svg'
import devices from '../assets/svg/devices.svg'
import user from '../assets/svg/user.svg'
import log from '../assets/svg/log.svg'
import logout from '../assets/svg/logout.svg'
import secondarylogo from '../assets/svg/secondarylogo.svg'
import logo from '../assets/logo.svg'
import menuIcon from '../assets/svg/icon-menu.svg'
import homeIcon from '../assets/svg/home-icon.svg'
import commentIcon from '../assets/svg/comments-icon.svg'
import administratorIcon from '../assets/svg/administrator-icon.svg'
import moderatorIcon from '../assets/svg/moderator-icon.svg'
import editorIcon from '../assets/svg/editor-icon.svg'
import logoutIcon from '../assets/svg/logout-icon.svg'
import logoBottom from '../assets/svg/logo-bottom.svg'
import arrowDown from '../assets/svg/arrow-down.svg'
import listIcon from '../assets/svg/listnews-icon.svg'
import addIcon from '../assets/svg/addnews-icon.svg'
import bars from '../assets/svg/bars.svg'
import mobileLogo from '../assets/svg/logo-mob.svg'

import listPageIcon from '../assets/svg/s1.svg'
import addPageIcon from '../assets/svg/s2.svg'
import contactIcon from '../assets/svg/s3.svg'
import settingsIcon from '../assets/svg/s5.svg'

moment.locale('sr')

import { API_ENDPOINT } from '../constants'

export class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      show: false,

      menu: false,
      categoryNews: false,
      news: false,
      documents: false,
      pools: false,
      admins: false,
      users: false,
      settings: false,
      absences: false,
      suggestions: false,
      newEmployees: false,
      deliveryLines: false,
      library: false,
      mobileMenu: false

    }
  }

  toggleClass = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }
  toggleMenu = () => {
    const currentState = this.state.show
    this.setState({ show: !currentState })
  }
  logout = () => {

    localStorage.removeItem('authToken');
    this.props[0].history.push('/login');
    window.location.reload(false)

  }

  componentDidMount() {


  }


  render() {

    if (!this.props.uData) {
      return null
    }

    return (
      <header>
        <div className='sidebar'>
          <div className='sidebar-content'>

            <div className="sidebar-header">
              <div className="sidebar-header-container">
                  <a href="/">
                    <div className='logo-header'>
                      <Isvg src={logo} className='logo-image' />
                      <Isvg src={mobileLogo} className="logo-mobile" />
                    </div>
                  </a>
                  <div className="help-menu"> 
                    <div className="language-wrap language-mobile">
                        <UncontrolledDropdown>
                          <DropdownToggle className="menu-link">
                            <span className="link-text">{this.props.lang == 'ba' ? 'BA' : 'ENG'}</span>
                            <Isvg src={arrowDown} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => this.props.setLang('ba')}>BA</DropdownItem>
                            <DropdownItem onClick={() => this.props.setLang('en')}>ENG</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    {
                      typeof window != 'undefined' && window.innerWidth < 768 ? 
                      <Isvg src={bars} onClick={() => {
                        this.setState({
                          mobileMenu: !this.state.mobileMenu
                        })
                      }} />
                      :
                      null
                    }
                  </div>
              </div>

              <ul className={this.state.mobileMenu ? 'sidebar-body open' : 'sidebar-body'}>

                <span className="menu-helper content-helper">POČETNA</span>
                <li className='menu__item'>
                  <Link to='/settings' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Podešavanja</span>
                  </Link>
                </li>

                <span className="menu-helper content-helper">MENI</span>
                <li className='menu__item'>
                  <Link to='/menu/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>

                <li className='menu__item'>
                  <Link to='/menu/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>


                <span className="menu-helper content-helper">KATEGORIJE</span>
                <li className='menu__item'>
                  <Link to='/news/categories/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/news/categories/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>

                <span className="menu-helper content-helper">POTKATEGORIJE</span>
                <li className='menu__item'>
                  <Link to='/news/subcategories/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/news/subcategories/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>


                <span className="menu-helper content-helper">BLOG</span>
                <li className='menu__item'>
                  <Link to='/news/blog/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/news/blog/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>

                {/* 
                <span className="menu-helper content-helper">NOVOSTI</span>
                <li className='menu__item'>
                  <Link to='/news/articles/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/news/articles/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>

                <span className="menu-helper content-helper">Reference</span>
                <li className='menu__item'>
                  <Link to='/references/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/references/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li> */}

                <span className="menu-helper content-helper">FORTIS GROUP</span>
                <li className='menu__item'>
                  <Link to='/fortis-group' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Fortis group</span>
                  </Link>
                </li>

                <span className="menu-helper content-helper">GALERIJA</span>
                <li className='menu__item'>
                  <Link to='/gallery' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Galerija</span>
                  </Link>
                </li>

                

                <span className="menu-helper content-helper">KONTAKTI</span>
                {/* <li className='menu__item'>
                  <Link to='/contact/settings' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Podešavanja</span>
                  </Link>
                </li> */}
                <li className='menu__item'>
                  <Link to='/contacts/list' className='menu__link'>
                    <span >
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>

                {/* <span className="menu-helper content-helper">DOKUMENTA</span>
                <li className='menu__item'>
                  <Link to='/documents/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/documents/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li> */}




                <span className="menu-helper content-helper">ADMINISTRATORI</span>
                <li className='menu__item'>
                  <Link to='/admins/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/admins/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>



                {/* <span className="menu-helper content-helper">KORISNICI</span>
                      <li className='menu__item'>
                        <Link to='/users/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/users/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li> */}





                <span className="menu-helper content-helper"></span>

                <li className='menu__item'>
                  <div className='menu__link' onClick={this.logout}>
                    <span className="logout-svg">
                      <Isvg src={logoutIcon} />
                    </span>
                    <span className='menu__text'>Odjavi se</span>
                  </div>
                </li>
              </ul>
            </div>


            {/* <div className='sidebar-footer'>
              <Isvg src={logoBottom} className='footer-icon' />
            </div> */}

          </div>
        </div>
      </header>
    )
  }
}

export default Header
