import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';

import BootstrapField from './field';
import OptionsField from './options';
import HTMLField from './html';
import Image from './image';
import Tags from './tags';
import EasySelect from './easySelect';
import Toggle from './toggle'
import Gallery from './gallery'
import Answers from './answers'
import Grid from './grid';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
const renderGridField = ({
    input,
    placeholder,
    label,
    multilang,
    lang,
    meta: { touched, error },
}) => (

    <Grid
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        multilang={multilang}
        lang={lang}
        {...input}
    />
)
const renderTagsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Tags
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderAnswersField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Answers
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


const renderGalleryField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Gallery
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)

const renderToggleField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Toggle
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


export const renderEasySelect = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    values
}) => (

    <EasySelect
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        {...input}

    >{children}</EasySelect>
)


export const renderField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    multilang,
    lang
}) => (

    <BootstrapField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        multilang={multilang}
        lang={lang}
        {...input}

    >{children}</BootstrapField>
)

export const renderOptionsField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    values
}) => (

    <OptionsField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        values={values}
        {...input}

    ></OptionsField>
)

export const renderHTMLField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    multilang,
    lang
}) => (

    <HTMLField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        multilang={multilang}
        lang={lang}
        {...input}

    ></HTMLField>
)


export const renderImageField = ({
    input,
    label,
    height,
    meta: { touched, error },
    hideInfo
}) => (

    <Image
        label={label}
        errorText={touched && error}
        error={touched && error}
        height={height}
        hideInfo={hideInfo}
        {...input}
    />
)


export const renderFields = (fields) => {
    return fields && fields.map((item, idx) => {
        if (item.type == 'div') {
            return (
                <div className={item.className}>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </div>
            )
        } else if (item.type == 'h5') {
            return (
                <h5 className={item.className}>
                    {item.text}
                </h5>
            )
        } else if (item.type == 'empty') {
            return (
                <>

                </>
            )
        } else if (item.type == 'button') {
            return (
                <Button type="button" className={item.className} color="primary">{item.text}</Button>
            )
        } else if (item.type == 'submit-button') {
            return (
                <Button type="submit" className={item.className} color="primary">{item.text}</Button>
            )
        } else if (item.type == 'col') {
            return (
                <Col className={item.className} lg={item.width.lg} sm={item.width.sm} xs={item.width.xs}>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Col>
            )
        } else if (item.type == 'row') {
            return (
                <Row className={item.className}>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Row>
            )
        } else if (item.type == 'options') {
            return (
                <Field
                    name={item.name}
                    component={renderOptionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                ></Field>

            )
        } else if (item.type == 'grid') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderGridField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multilang={item.multilang}
                    lang={item.lang}
                ></Field>

            )
        } else if (item.type == 'html') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderHTMLField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multilang={item.multilang}
                    lang={item.lang}
                ></Field>

            )
        } else if (item.type == 'image') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    hideInfo={item.hideInfo}
                    component={renderImageField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'gallery') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderGalleryField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'switch') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderToggleField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'tags') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderTagsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'answers') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderAnswersField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'easy-select') {
            return (
                <Field
                    name={item.name}
                    component={renderEasySelect}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    values={item.values}
                >

                </Field>
            )
        } else {
            return (
                <Field
                    name={item.name}
                    component={renderField}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={item.type}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    optionValue={item.optionValue}
                    afterText={item.afterText}
                    beforeText={item.beforeText}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    multilang={item.multilang}
                    lang={item.lang}
                >
                    {item.values && item.values.length ? [{ name: 'Izaberite', value: null }, ...item.values].map((option, oidx) => {
                        return (

                            <option key={oidx} value={option.value}>{option.name}</option>
                        )
                    })
                        :
                        null
                    }
                </Field>
            )
        }

    })
}
